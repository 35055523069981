import Common from "./module/common.js";
import jQuery_ui from './module/jquery-ui.min.js';
import Validate from './module/jquery.validate.js'; 
import Yubinbango from './module/yubinbango.js'; 
import ScrollHint from 'scroll-hint';
import ScrollHintCss from 'scroll-hint/css/scroll-hint.css';



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* 画像のSP PC切替
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
$(function() {
	"use strict";
  // 置換の対象とするclass属性。
  var jQueryelem = $('.img-switch');
  // 置換の対象とするsrc属性の末尾の文字列。
  var sp = '_sp.';
  var pc = '_pc.';
  // 画像を切り替えるウィンドウサイズ。
  var replaceWidth = 768;

  function imageSwitch() {
    // ウィンドウサイズを取得する。
    var windowWidth = parseInt($(window).width());

    // ページ内にあるすべての`.js-image-switch`に適応される。
    jQueryelem.each(function() {
      var jQuerythis = jQuery(this);
      // ウィンドウサイズが768px以上であれば_spを_pcに置換する。
      if(windowWidth >= replaceWidth) {
        jQuerythis.attr('src', jQuerythis.attr('src').replace(sp, pc));

      // ウィンドウサイズが768px未満であれば_pcを_spに置換する。
      } else {
        jQuerythis.attr('src', jQuerythis.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch();

  // 動的なリサイズは操作後0.2秒経ってから処理を実行する。
  var resizeTimer;
  $(window).on('resize', function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      imageSwitch();
    }, 200);
  });
});


/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* スクロールヒント
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
new ScrollHint('.js-scrollable', {
  
    applyToParents: true,
    i18n: {
      scrollable: 'スクロールできます'
    }
  });

/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
 jquery.validateの設定
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー*/
function form_validate_func(){
	
	jQuery.validator.addMethod("numericdashe", function (value, element) {
  if (/^[0-9\-]+$/i.test(value)) {
      return true;
  } else {
      return false;
  };
}, "半角数字とダッシュのみ有効");
	
	  var methods = {
		電話番号: function(value, element){
        return this.optional(element) || /^[0-9-]+$/.test(value);
  }
  };
	 //メソッドの追加
  $.each(methods, function(key) {
    $.validator.addMethod(key, this);
  });
	
var rules = {
        お名前: {
          required: true, 
        },
        フリガナ: {
          required: true, 
        },
        電話番号: {
          required: true, 
								numericdashe: true,
        },
        メールアドレス: {
          required: true, 
									email: true,
        },
					   ご相談内容: {
          required: true, 
        },
        個人情報の取扱い: {
          required: true, 
        },
    };
    // エラーメッセージ
var messages={
        お名前: {
            required: 'お名前を入力してください',
        },
	        フリガナ: {
          required: 'フリガナを入力してください', 
        },
        電話番号: { 
            required: '電話番号を入力してください',
									number: '電話番号をを入力してください',
        },
        メールアドレス: {
            required: 'メールアドレスを入力してください',
										email: '有効なメールアドレスを入力してください',
        },
					    ご相談内容: {
            required: 'ご相談内容を入力してください',
        },
        個人情報の取扱い: {
          required:'個人情報の取扱い同意をお願いします',
        },
    };
	
$('#mailform').validate({
rules: rules,
messages: messages,
 

});

}


/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
 添付ファイルボタンをカスタマイズ
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー*/
function upfilebtn_func(){
  $('.upfile').on('change', function () { //ファイルが選択されたら

    let filenum = $(this).parents('.upfile-Wrap').data('filenum');

		$.each(this.files, function(i, f) {
      $('.upfileLabel-filename').append($('<li/>').text(f.name).addClass(filenum));
		});
    $(this).parents('.upfile-Wrap').children('.upfileLabel-fileclear').css('display', 'inline-block'); //クリアボタンを表示

  });
  $('.upfileLabel-fileclear').click(function() { //クリアボタンがクリックされたら
    $(this).parents('.upfile-Wrap').find('.upfile').val(''); //inputをリセット
    let filenum = $(this).parents('.upfile-Wrap').data('filenum');

    $('.upfileLabel-filename').children('.'+filenum).remove(); //ファイル名をリセット
    $(this).hide(); //クリアボタンを非表示
  });
}
	



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
 * inview
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー*/
function inview_func(){
var inviewDeferred = $.Deferred();

if ($('#wrapper').hasClass('inview--start')){
inviewDeferred.resolve();
}
inviewDeferred.done(function (){

inview_curtain();//隠したいアイテムをspanやdivでくくりそこにclass名（inview_curtain）s
inview_fadeIn();//フェードインclass名（inview_fadeIn）
inview_fadeInUp();  //フェードインclass名（inview_fadeInUp）
	inview_zoomIn();
inview_textyle(); //テキストエフェクトclass名（inview_textyle）
effectFlug();     //inviewでフラグ立てたいとき用class名（effectFlug）をつけたものに（effect_start）クラスがつく
});
   
function effectFlug() {   
      $('.effectFlug').one('inview', function (event, isInView ) {

        $(this).addClass('effectFlug--now');
        if (isInView) {
        $('.effectFlug--now').each(function (i) {
        $(this).delay(i * 100).queue(function () {
        $(this).addClass('effect_start').removeClass('effectFlug--now').dequeue();
        });
        });
        }
        });
	}
function inview_fadeIn() {   
      $('.inview_fadeIn').one('inview', function (event, isInView) {
        $(this).addClass('inview_fadeIn--now');
        if (isInView) {
        $('.inview_fadeIn--now').each(function (i) {
        $(this).delay(i * 100).queue(function () {
        $(this).addClass('animated animate__fadeIn').removeClass('inview_fadeIn--now inview_fadeIn').dequeue();
        });
        });
        }
        });
}
function inview_fadeInUp() {   
      $('.inview_fadeInUp').one('inview', function (event, isInView) {
        $(this).addClass('inview_fadeInUp--now');
        if (isInView) {
        $('.inview_fadeInUp--now').each(function (i) {
        $(this).delay(i * 100).queue(function () {
        $(this).addClass('animated animate__fadeInUp').removeClass('inview_fadeInUp--now inview_fadeInUp').dequeue();
        });
        });
        }
        });
}
	function inview_zoomIn() {   
      $('.inview_zoomIn').one('inview', function (event, isInView) {
        $(this).addClass('inview_zoomIn--now');
        if (isInView) {
        $('.inview_zoomIn--now').each(function (i) {
        $(this).delay(i * 100).queue(function () {
        $(this).addClass('animated animate__zoomIn').removeClass('inview_zoomIn--now inview_zoomIn').dequeue();
        });
        });
        }
        });
}
	
function inview_curtain() {  
       $('.inview_curtain').one('inview', function (event, isInView) {
       $(this).addClass('inview_curtain--now');
       if (isInView) {
       $('.inview_curtain--now').each(function (i) {
       $(this).delay(i * 200).queue(function () {
       $(this).addClass('curtain-start').removeClass('inview_curtain--now').dequeue();
       });
       });
       }
       });
}
function inview_textyle() {   
								$('.inview_textyle').one('inview', function (event, isInView) {
								$(this).addClass('inview_textyle--now');
								if (isInView) {
								$('.inview_textyle--now').each(function (i) {
								$(this).delay(i * 150).queue(function () {
								$(this).textyle({
								// duration : 400, //エフェクト時間(ミリ秒)
								delay : 50, //文字間のエフェクト間隔(ミリ秒)
								easing : 'swing', //エフェクトのイージングパターン
								}); 
								$(this).addClass('textyle').removeClass('inview_textyle--now').dequeue();

								});
								});
								}
								});
}

 
}


/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
*スマホメニューボタン
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function spToggleBtn_func() {
  //デバイス判定（タッチが有効か否か）
  var isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
  //デバイス判定によるイベントの決定
  var eventType = (isTouchDevice) ? 'touchend' : 'click';
  $(".sp_btn").on("click", function () {
  $("html").toggleClass("spmenu-open");
   $("#sp-naviArea").slideToggle(300);
  });

var window_width = window.innerWidth;
	var timer = false;
	
	$(window).on('orientationchange resize', function () {
    var resize_width = window.innerWidth;
    if (window_width != resize_width) {
        if (timer !== false) {
            clearTimeout(timer);
        }
timer = setTimeout(function () {
        $("html").removeClass("spmenu-open");
        $("#sp-naviArea").fadeOut("fast");
        window_width = resize_width;
        }, 100);
    }
});
//メニューの中クリックしたらとじる
	    $('#sp-menu a').on('click', function() {
        $('#sp-naviArea').slideUp("fast");
     $("html").removeClass("spmenu-open");

    });
	// 表示したポップアップ以外の部分をクリックしたとき
	$(document).on('click touchend', function(event) {
		
  if (!$(event.target).closest('#header').length) {
 $('#sp-naviArea').slideUp("fast");
     $("html").removeClass("spmenu-open");

  }		
		
});

}







/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* back_to_top
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function backtotop_func() {

	"use strict";
  var $win = $(window),
   $back_to_top = $('.backtotop'),
  showClass = 'backtotop-visible',
//一番上のスクロールアニメーションの長さ（ミリ秒）
        scroll_top_duration = 700;
  $win.on('load scroll', function() {
	 var contactWrapOffsetTop =  document.getElementById('footer').offsetTop,
   thisScrollTop = $(this).scrollTop(),
		 scrollBottom = $(window).scrollTop() + $(window).height();

    if ( thisScrollTop >500 ) {
	
		  if(scrollBottom >= contactWrapOffsetTop){
  $back_to_top.removeClass(showClass);
		
  }
  else{
  $back_to_top.addClass(showClass);
		
  }
   
    } else {
      $back_to_top.removeClass(showClass);
    }
  });
    
var clickEventType=((window.ontouchstart!==null)?'click':'touchstart');
    $back_to_top.on(clickEventType, function() {
      
      
  $('body,html').animate({ scrollTop: 0 , }, scroll_top_duration );
    });
    
}

/*******************
* smooth scroll
********************/

function smooth_scroll_func() {
	const header = $('#header');
	/* ---------- ページ内リンクの処理 ---------- */
	$('a[href^="#"]').click(function(){
		var headerHight = header.outerHeight();

		var href= $(this).attr('href');
		var hash_str = href.replace( '#' , '' );
		if(document.getElementById(hash_str) != null ) {
			var target = $(href == '#' || href == '' ? 'body' : href);
			var position = target.offset().top - headerHight;
			$('html, body').animate({scrollTop:position}, 550, 'swing');

			return false;
		} else {
			return false;
		}

	});
	/* ---------- 他ページ内リンクの処理 ---------- */
	var url = $(location).attr('href');
	if (url.indexOf('?id=') == -1) {
		return false;
	}else{
		var url_sp = url.split('?id=');
		var id_str = url_sp[url_sp.length - 1];
		var hash = '#' + url_sp[url_sp.length - 1];
		if(document.getElementById(id_str) != null ) {
			var target2 = $(hash);
			var position2 = target2.offset().top - headerHight;
			$('html, body').animate({scrollTop:position2}, 550, 'swing');
		} else {
			return false;
		}
	}
 }




/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* 画像のSP PC切替
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function imgswitch_func() {
	"use strict";
  // 置換の対象とするclass属性。
  var $elem = $('.img-switch');
  // 置換の対象とするsrc属性の末尾の文字列。
  var sp = '_sp.';
  var pc = '_pc.';
  // 画像を切り替えるウィンドウサイズ。
  var replaceWidth = 768;
var currentWidth = window.innerWidth;
  function imageSwitch() {
    // ウィンドウサイズを取得する。
    var windowWidth = parseInt($(window).width());

    // ページ内にあるすべての`.img-switch`に適応される。
    $elem.each(function() {
      var $this = $(this);
      // ウィンドウサイズが768px以上であれば_spを_pcに置換する。
      if(windowWidth >= replaceWidth) {
        $this.attr('src', $this.attr('src').replace(sp, pc));

      // ウィンドウサイズが768px未満であれば_pcを_spに置換する。
      } else {
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch();

  // 動的なリサイズは操作後0.1秒経ってから処理を実行する。
    var resizeTimer;
	
	
	window.addEventListener("resize", function() {
	if (currentWidth === window.innerWidth) {return;}// ウインドウ横幅が変わっていないため処理をキャンセル。
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function() { imageSwitch();}, 100);
});

}



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* //固定サイドメニュー
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function fixedSide_func() {
    $(".fixedSide-clickArea").click(function () {
        
		if( $(this).parent().css('right') === '0px' ){
        	$(this).parent().animate({right:'-200px'}, {queue: false, duration: 50});
				$(this).parent().removeClass('fixedSide-open');
		} else {
			$(this).parent().animate({right:'0px'}, {queue: false, duration: 50});
			$(this).parent().addClass('fixedSide-open');
		}
         
    });
	
	// メールボタン押したら閉じる
	    $(".fixedSide-btn a").click(function () {
   $('#fixedSide').animate({right:'-200px'}, {queue: false, duration: 50});
				$('#fixedSide').removeClass('fixedSide-open');
 });
	
	// 表示したポップアップ以外の部分をクリックしたとき
	$(document).on('click touchend', function(event) {
  if (!$(event.target).closest('#fixedSide').length) {
   $('#fixedSide').animate({right:'-200px'}, {queue: false, duration: 50});
				$('#fixedSide').removeClass('fixedSide-open');
  }
});
	

}

/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* //固定ボトムメニュー
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function fixedBottom_func() {
    $(".fixedBottom-clickArea").click(function () {
        
		if( $(this).parent().hasClass('fixedBottom-open')){
				$(this).parent().children('.fixedBottom-inner').slideToggle();
				$(this).parent().removeClass('fixedBottom-open');
		} else {
				$(this).parent().children('.fixedBottom-inner').slideToggle();
			$(this).parent().addClass('fixedBottom-open');
		}
 });
	
// メールボタン押したら閉じる
	    $(".fixedBottom-mail a").click(function () {
	$('.fixedBottom-inner').slideUp();
				$('#fixedBottom').removeClass('fixedBottom-open');
 });

// 表示したポップアップ以外の部分をクリックしたとき
	$(document).on('click touchend', function(event) {
  if (!$(event.target).closest('#fixedBottom').length) {
	$('.fixedBottom-inner').slideUp();
				$('#fixedBottom').removeClass('fixedBottom-open');
  }
});
	
	
}


/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* faqのアコーディオン
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function accordion_func() {
	$('.faq-accordion dt').append('<span class="plus">');
$('.faq-accordion dt').click(function(){
		$(this).toggleClass("open");
		$(this).next().slideToggle();
	});
}


var currentWidth = window.innerWidth;
  function faqSwitch() {
    // ウィンドウサイズを取得する。
    var windowWidth = parseInt($(window).width());

      if(windowWidth >= 768) {
        $('.faq-accordion dd').show();
        $('.faq-accordion dt').removeClass("open");
        $('.faq-accordion .plus').hide();
      } else {
        $('.faq-accordion .plus').show();
        $('.faq-accordion dd').hide();
      }

  }


  // 動的なリサイズは操作後0.1秒経ってから処理を実行する。
    var resizeTimer;
		window.addEventListener("load", function() {
      faqSwitch();
    });
	
	window.addEventListener("resize", function() {
    var windowWidth = parseInt($(window).width());
	if (currentWidth === window.innerWidth) {return;}// ウインドウ横幅が変わっていないため処理をキャンセル。
  if (windowWidth < 768) {return;}// ウインドウ横幅が変わっていないため処理をキャンセル。
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function() { faqSwitch();}, 100);
});




/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* スマホのみTEL有効（spanに.telをつけてaltに電話番号いれるとスマホでaタグのtel:●●●●●に変わる）
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/

	function spTelChange_func() {
        if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
        $(function() {
        $('.tel').each(function() {
        var str = $(this).html();
        if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
        } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
        }
        });
        });
        }
}
 



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
呼び出し
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
$(function(){
 form_validate_func();
upfilebtn_func();

backtotop_func();

imgswitch_func();

spToggleBtn_func();
inview_func();
accordion_func();
 fixedSide_func();
fixedBottom_func();
	spTelChange_func();

})
$(window).on('load',function(){
	smooth_scroll_func();
})